import { useCallback, useContext, useEffect } from 'react';
import { ActionsContext } from 'shared/components/data-table/context/actions.context';

export const usePaginateCallback = (onPagination, {pageNumber = 1, pageSize}: PageableRequest) => {
  const context = useContext(ActionsContext);

  const paginate = useCallback((innerPageNumber?: number, innerPageSize?: number) => {
    onPagination({
      pageNumber: innerPageNumber || pageNumber,
      pageSize: innerPageSize || pageSize
    });
  }, [onPagination, pageNumber, pageSize]);

  useEffect(() => {
    if (context) {
      context.registerPaginator(paginate);
    }
  }, [paginate, context != null]);

  return paginate;
}
