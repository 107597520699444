import React from 'react';
import classNames from 'classnames';

import { Button, ButtonProps } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { usePageList } from 'shared/components/pagination/hooks/use-page-list.hook';
import { usePaginateCallback } from 'shared/components/pagination/hooks/use-paginate-callback.hook';

import './pagination.component.scss';

interface PaginationProps {
  totalItems: number;
  values: PageableRequest;
  onPagination: (settings: PageableRequest) => any;
}

export const Pagination: React.FC<PaginationProps> = ({totalItems, values, ...props}) => {
  const paginate = usePaginateCallback(props.onPagination, values);

  const {pageNumber, pageSize} = values;
  const [pages, paginationButtons] = usePageList(totalItems, values);

  return (
    <div data-items={totalItems} data-pages={pages?.length}>
      {Boolean(totalItems && pages?.length && pages.length !== 1) &&
      <div className="pagination pagination-component">
        <div className="state-description hidden-xs">
          <Translation resource="PAGE_OF" replacements={[pageNumber, pages?.length]}/>
        </div>
        <div className="button-container">

          <PaginationButton
            onClick={() => paginate(1, pageSize)} disabled={pageNumber === 1}
            className="btn-first hidden-xs"
          >
            &lt;&lt;
          </PaginationButton>

          <PaginationButton
            onClick={() => paginate(pageNumber - 1, pageSize)} disabled={pageNumber === 1}
            className="btn-previous"
          >
            &lt;<span className="hidden-lg hidden-md hidden-sm">&nbsp;<Translation resource="PREVIOUS"/></span>
          </PaginationButton>

          {paginationButtons?.map((buttonPage) =>
            <PaginationButton
              key={buttonPage.page}
              btnRef={ref => ref?.blur()} /* This forces the button to become non ":active" after it is clicked and re-generated */
              onClick={() => paginate(buttonPage.page, pageSize)}
              className={classNames('btn-page hidden-xs', {'btn-currentPage': buttonPage.current})}
            >
              {buttonPage.page}
            </PaginationButton>
          )}
          <span className="state-description hidden-lg hidden-md hidden-sm">
              <Translation resource="PAGE_OF" replacements={[pageNumber, pages?.length]}/>
            </span>
          <PaginationButton
            onClick={() => paginate(pageNumber + 1, pageSize)} disabled={pageNumber === (pages?.length)}
            className="btn-next"
          >
            <span className="hidden-lg hidden-md hidden-sm"><Translation resource="NEXT"/>&nbsp;</span>&gt;
          </PaginationButton>

          <PaginationButton
            onClick={() => paginate((pages?.length), pageSize)}
            disabled={pageNumber === (pages?.length)}
            className="btn-last hidden-xs"
          >
            &gt;&gt;
          </PaginationButton>

        </div>
      </div>
      }
      {Boolean(totalItems && pages?.length && (pages.length === 1)) &&
      <div className="pagination pagination-component">
          <span className="state-description">
              <Translation resource="PAGE_OF" replacements={[pageNumber, pages?.length]}/>
            </span>
      </div>
      }
    </div>
  );
};

interface OwnProps {
  className?: string;
  btnRef?: (ref: HTMLButtonElement) => any;
}

type PaginationButtonProps = OwnProps & ButtonProps;

const PaginationButton = ({className, btnRef, ...props}: PaginationButtonProps) =>
  <Button btnDefault className={classNames('btn-sm', className)} btnRef={btnRef} {...props}>{props.children}</Button>;
