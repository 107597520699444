import { useMemo } from 'react';

export const useButtonArray = (pages: number[], currentPage: number): PaginationButton[] => {
  return useMemo(
    () => {
      const currentIndex = currentPage - 1;

      let lowBound = currentIndex;
      let highBound = currentIndex;

      while (lowBound > currentIndex - 2 && lowBound > 0) {
        lowBound--;
      }
      while (highBound < currentIndex + 2 && highBound < pages.length - 1 && (highBound - lowBound) < 4) {
        highBound++;
      }
      while (highBound - lowBound < 4 && lowBound > 0) {
        lowBound--;
      }
      while (highBound - lowBound < 4 && highBound < pages.length - 1) {
        highBound++;
      }
      return Array(highBound - lowBound + 1)
        .fill(1)
        .map((x, i) => {
          const buttonPage = i + lowBound + 1;
          return {page: buttonPage, current: (buttonPage === currentPage)};
        });
    },
    [pages, currentPage]);
};
