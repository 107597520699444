import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showModal } from 'shared/components/modal/modal.actions';
import { LOAD_NOTIFICATION_MODAL } from 'app/globals/constants';
import { useButtonClickStream } from 'shared/components/click-stream/use-button-clickstream.hook';
import { ButtonType } from 'shared/models/click-stream/button-type.model';
import { AddAlertOutlined } from '@mui/icons-material';

interface Props {
    disabled?: boolean;
  }
  
  export const LoadNotificationButton = ({ disabled }: Props) => {
      const { t } = useTranslation();
      const dispatch = useDispatch();

      const onClick = () => {
          dispatch(showModal(LOAD_NOTIFICATION_MODAL));
      };

      const handleClick = useButtonClickStream('notify-me', ButtonType.Button, true, null, null, null, onClick);

      return (
        <div className="notify-me" >
          <Button 
            startIcon={<AddAlertOutlined/>} 
            variant='text' 
            disabled={disabled} 
            onClick={handleClick}>
              {t('NOTIFY_ME')} 
            </Button>
        </div>
      );
  };