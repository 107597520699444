import { useEffect, useState } from 'react';
import { useButtonArray } from 'shared/components/pagination/hooks/use-button-array.hook';

export const usePageList = (totalItems: number, {pageSize, pageNumber}: PageableRequest): [number[], PaginationButton[]] => {
  const [pages, setPages] = useState<number[]>([]);
  const paginationButtons = useButtonArray(pages, pageNumber);

  useEffect(() => {
      if (totalItems) {
        const numberOfPages = Math.ceil(totalItems / pageSize);
        setPages(Array(numberOfPages).fill(1).map((x, i) => i));
      }
    },
    [totalItems, pageSize, pageNumber]
  );

  return [pages, paginationButtons];
};
