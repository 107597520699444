import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';
import { CarrierQualificationStatus } from 'shared/enums/carrier-qualification-status.enum';
import { AppRoute } from 'app/routesEnum';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './restricted-banner.component.scss'

export const RestrictedBanner = () => {
  const { t } = useTranslation();
  const qualificationStatus = useCarrierDetails()?.carrierQualificationStatus;
  const showBanner: boolean = qualificationStatus == CarrierQualificationStatus.RESTRICTED;

  if (showBanner) {
    return (
      <div className='restricted-banner-container'>
        <div className='restricted-area'>
          <div className='restricted-logo'><img src='/assets/images/lock_white.svg' alt="warning" /></div>
        </div>
        <div className='restricted-banner-text-container'>
          <div className='restricted-text-area'>
            {t('CAP_RESTRICTED_BANNER')}
          </div>
          <div className='bottom-link'>
            <Link to={AppRoute.DASHBOARD}>
              {t('VIEW_CARRIER_STATUS')}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
